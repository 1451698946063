html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );

  --imp-text-color: #9ADDFF;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #000000;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9ADDFF;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #00000000 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.086) !important;
  backdrop-filter: blur(10px) !important;
}

.navbar {
  /* position: fixed !important; */
  transition: all 0.3s ease-out 0s !important;
  /* padding: 0.0rem 0.3rem !important; */
  font-size: 1.1rem !important;
}


.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;  
  background-color: #ffffff !important;
  backdrop-filter: blur(10px) !important;
  transform: rotate(0deg) !important;
  /* left: 0 !important; */
  opacity: 1 !important;
}






.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
  
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
  
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
  
}

@media (max-width: 767px) {



  .navbar-nav {
    margin-top: -1em;
    padding: 4rem 1rem 20rem 1rem !important;
    background-color: rgba(0, 0, 0, 0) !important;
    font-size: 1.1rem !important;

    /* min-height: 100% !important; */
    /* justify-content: center !important; */

  }

  .sticky {
    background-color: #0000002e !important;
    
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.086) !important;
    backdrop-filter: blur(10px) !important;
    
    
  }

  .navbar-toggler:active {
    outline: 0 !important;
  
  }

  .navbar-toggler {
    margin-top: 0.1rem;
    margin-right: 1rem;
    
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
    
    /* backdrop-filter: blur(10px) !important; */
    /* margin-right: 1.2rem; */
  }
  .navbar-nav .nav-item {
    /* display: none !important; */
    /* backdrop-filter: blur(10px) !important; */
    /* margin-right: 1rem; */
    /* margin-bottom: rem; */
    align-self: center !important;
    justify-self: center !important;
    margin-left: 50% !important;
    padding-top: 5% !important;
    padding-bottom: 5% !important;
    margin-right: 50%;
  
  }

    .navbar-toggler span {
    display: block !important;
    background-color: #ffffff !important;
    height: 2px !important;
    width: 20px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    /* left: 0 !important; */
    opacity: 1 !important;
    }
  
  
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}


.d-flex {
  height: 5.0em !important;
  margin-top: -1rem !important;
  /* width: 5em !important; */
  margin-bottom: 0.3em;
  padding-left: 1.5rem;
  margin-right: 0rem !important;
}

  @media screen and (min-width: 100px) and (max-width: 920px){ 
  .d-flex {
  height: 6rem !important;
  /* width: 5em !important; */
  /* margin-left: -10px; */
  margin-top: 0.1rem;
  }
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  /* padding: 1.2rem 1rem !important; */
}

/* @media (max-width: 767px) {
  .nav-link {
    padding: 4 0rem !important;
  }
} */

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
  /* margin-bottom: 20px; */
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 2px;
  width: 0;
  border-radius: 16px;
  background: #ffffff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}


.home-section {
  /* position: relative; */
  /* z-index: -1; */
  background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
  /* background-position: top center; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -444px;
  /* background-position: ; */
  /* background-clip: 0%; */
  /* margin-left: 0% !important; */
  /* margin-right: 0% !important; */
  padding-bottom: 45em !important;
  padding-top: 28em !important;
  text-align: left;

}

.home-content {
  /* padding: 12rem 0 0rem !important; */
  color: rgb(255, 255, 255);
  /* margin-top: -20rem; */
}
/* 
.heading {
  font-size: 4em !important;
  padding-left: 50px !important;
} */

.heading-name {
  margin-top: -1.5em;
  font-size: 4.0em !important;
  /* padding-left: 50px !important; */
  text-align: left; 
  padding-bottom: 5px !important;
  
}

.main-name {
  color: #000000;
}


@media (max-width: 767px ) {
  .heading-name {
    font-size: 2.2rem !important;
    text-align: center !important;
    margin-top: -12rem !important;
    padding-left: 0px !important;
    /* margin-left: auto !important; */
    /* margin-right: auto !important; */
    font-weight: 200 !important;
    /* letter-spacing: 2px; */
  }

  .home-section {
    /* position: relative; */
    background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
    background-position-y: -300px;
    /* text-align: left; */
    text-align: center !important;

    /* padding-bottom: 10px !important; */
    /* padding-top: 10px !important; */
  }

  
} 

.Typewriter__wrapper {
  font-size: 1.5em !important;
  color: #000000 !important;
  text-align: left;

 
  /* font-weight: 600 !important; */
}
.Typewriter__cursor {
  font-size: 1.5em !important;
  color: #000000 !important;
  text-align: left;

  
  
  
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.05em !important;
    font-weight: 400 !important;

    /* position: absolute !important; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* padding-left: 25% !important; */
    /* margin-left: auto !important; */
    /* margin-right: auto !important; */
    text-align: center !important;
    
    /* justify-self: center !important;  */
    /* padding-left: 0px !important; */
  }
  .Typewriter__cursor {
    font-weight: 400 !important;
    font-size: 1.05em !important;
    color: #000000 !important;
    /* position: absolute !important; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    text-align: center !important;
    /* justify-self: center !important;  */
  }

}

 .myAvatar {
  justify-content: center !important; 
  padding-top: 0rem !important;
  padding-right: 14rem;
} 

.img-fluid {
  padding-left: 5px;
  padding-top: 20px;
  max-width: 370px;
  max-height: 370px;
}

@media (max-width: 767px) {
  .img-fluid {
    justify-content: center !important;
    /* padding-top: 2rem !important; */
    /* padding-bottom: 2rem !important; */
    padding-right: 0rem;
    padding-left: 0rem;
    max-width: 280px;
    max-height: 280px;
  }

  .myAvatar {
    justify-content: center !important; 
    /* padding-top: 7rem !important; */
    /* padding-right: 14rem; */
    
  } 
} 

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  margin-top: -100px;
}

.home-about-description {
  color: white !important;
  padding-top: 0px !important;
  padding-bottom: 20px !important;

  text-align: center;
  
}

@media (max-width: 767px) {
  .home-about-body {
    padding-top: -50px !important;
    margin-top: -30px !important;
    padding-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3%;
    padding-right: 3%;
    /* padding-right: 2.5rem; */
    /* padding-left: 1.1rem; */
    text-align: center !important; 
    justify-content: center !important;

  }

  .home-about-description {
    color: white !important;
    padding-top: -80px !important;
    padding-bottom: 20px !important;
  
    text-align: center;
    
  }


.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  margin-top: -280px !important;

}
}
.home-about-body {
  padding-top: 50px;
  padding-left: 40px !important;  
  padding-right: 25px !important;  

  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 0px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 45px !important;
  height: 45px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 200% !important;
  background: rgba(0, 0, 0, 0.972) !important;
  border-radius: 50% !important;
  border: 1.7px solid #9ADDFF88 !important;
  /* border-color: #9ADDFF !important; */
  /* box-shadow: 0px 0px 0px 1px #9ADDFF; */
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* background: #9ADDFF; */
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #9ADDFF;
}

.home-social-icons:hover {
  color: #9ADDFF;
  box-shadow: 0 0 5px #9ADDFF;
  text-shadow: 0 0 2px #9ADDFF;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #ffffff !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  /* background-color: rgba(0, 0, 0, 0); */
  bottom: 0 !important;

  padding-top: 50px !important;
  padding-bottom: 30px !important ;
}
.footer-copywright {
  text-align: center !important;
  
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
    align-items: center;
  }

  .footer h3 {
    padding-left: 0px !important;
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    align-self: center;
  }
}

.footer h3 {
  /* padding-left: 50px; */
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  /* padding-right: 60px; */
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 0px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  border-radius: 20px !important;
  padding-top: 35px !important;
  padding-bottom: 30px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;

  height: auto !important;
}

.project-card-view {
  border: 1.7px solid #9ADDFF88 !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  border-radius: 40px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top:20px;
  /* padding-bottom: 20px; */
  
}
.project-card-view:hover {
  transform: scale(1.1) !important;
  overflow: hidden !important;
  box-shadow: 0 1px 15px 7px  #9ADDFF !important;
  border-radius: 40px !important;

}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
  
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(237, 118, 243, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.9 !important;
  border-radius: 20px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 1 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #000000 !important;
  border: 1.7px solid #9ADDFF88 !important;
  border-radius: 15px !important;
  max-width: 144px !important;
  min-width: 144px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}


.btn-top {
  color: #fff !important;
  background-color: #000000 !important;
  border: 1.7px solid #9ADDFF88 !important;
  border-radius: 15px !important;
  /* margin-left: 77px !important; */
  /* margin-right: 77px !important;   */
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-bottom: 20px !important;
  max-width: 144px !important;
  min-width: 144px !important;
  align-items: center !important;
  align-self: center !important;

}

@media screen {
  .btn-top {
    color: #fff !important;
    background-color: #000000 !important;
    border: 1.7px solid #9ADDFF88 !important;
    border-radius: 15px !important;
    /* margin-left: 22px !important; */
    /* margin-right: 22px !important;   */
    padding-top: 8px !important;
    padding-bottom: 8px !important;

  }
}


.btn-primary:hover {
  color: #fff !important;
  /* background-color: #9ADDFF88 !important; */
  border-radius: 15px !important;
  transition: 0.5s;
  transform: scale(1.07) !important;
  overflow: hidden !important;
  box-shadow: 0 1px 15px 1px  #9ADDFF !important;
}

.btn-top:hover {
  color: #fff !important;
  /* background-color: #9ADDFF88 !important; */
  border-radius: 15px !important;
  transition: 0.5s;
  transform: scale(1.07) !important;
  overflow: hidden !important;
  box-shadow: 0 1px 15px 1px  #9ADDFF !important;
}

.btn:focus {
  /* outline: none !important; */
  box-shadow: 0 0 15px #9ADDFF;
  border-radius: 20px !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  padding-bottom: 40px;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
  width: 95% !important; 
}

.tech-icons {
  font-size: 4.5em !important;
  color: white;
  margin: 20px !important;
  opacity: 0.93 !important;
  border: 1.7px solid #9ADDFF88 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 30px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  padding: 30px !important;

  
}

@media screen and (min-width: 100px) and (max-width: 920px){ 
  .tech-icons {
    margin-left: 25px !important;
    margin-right: 25px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 0px !important;
    padding-bottom: 15px !important;
    /* margin: 5% !important; */


      
  }

  .tech-icon-images {
    /* margin-bottom: 80px !important; */
    
    line-height: 1.0 !important;
  
  }
}

.tech-icons:hover {
  transform: scale(1.1) !important;
  overflow: hidden !important;
  /* border-color: #9ADDFF !important; */
  box-shadow: 0 1px 15px 7px  #9ADDFF !important;
  border: 1.7px solid #9ADDFF88 !important;
}
.tech-icon-images {
  padding: 30px !important;
  line-height: 1.0 !important;

}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

/* .about-activity {
  list-style: none !important;
  text-align: left !important;
} */

/* .calendar {
  color: white;
  font-size: x-large;
  

} */

/* .git.calendar {
  width: 80%;
  margin: auto;
  background-color: aliceblue !important;
  
} */



.about-img {
  max-width: 25rem;
  padding-top: 0 !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0px !important;
  }

  .about-activity {
    list-style: none !important;
    text-align: center !important;
    color: white;
    /* padding-left: 1px !important; */

  }

}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-size: contain;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 70px;
  padding-bottom: 150px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}


.resume-header {
  padding-top: 7rem;
}

@media (max-width: 767px) {

  .resume-header {
    padding-top: 2rem;
    font-size: 0.5rem;
  }

  .resume-section {
    position: relative !important;
    padding-top: 140px !important;
    padding-bottom: 0 !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
  }
  
  .resume {
    padding-top: 30px;
    padding-right: 50px !important;
    padding-left: 50px !important;
    /* padding-bottom: 15px; */
    justify-content: center!important;
  }


  .button-row {
    padding-top: 16rem !important;
  }
}
/* 
.button-row {
  padding-top: 75rem !important;
  padding-bottom: 6rem !important;
} */

.resume-row {
  margin-top: -2rem;
  padding-top: 0px;
  padding-bottom: 0px;
  align-content: center !important;
  justify-content: center !important;
  align-items: center!important;


}

.button-row {
  padding-top: 69rem;
  padding-bottom: 80px;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #9ADDFF88  !important;
  border-color: #9ADDFF88 !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
