
  
  .calandar{
    background-color: rgba(0, 0, 0, 0);  
    margin: 48px auto; 
    max-block-size: 2050px;
    text-align: center; 
    padding-bottom: 80px;
    border-radius: 35px;  
    color: white; 
    width: 98%; 
    
  }
  
  .calandar>*{ 
    border: 1.7px solid #9ADDFF88 !important;
    border-radius: 35px; 
    overflow: hidden !important;
    padding: 26px;  
    
       
  } 
  
  .calandar>*:hover{ 
    border: 1.7px solid #9ADDFF88 !important; 
    overflow: hidden !important;
    box-shadow: 0 1px 15px 7px  #9ADDFF !important;
    transform: scale(1.1) !important;
    border-radius: 35px; 
    padding: 26px;  
    /* margin: auto; */
  } 
  
  
  @media (max-width: 767px) { 

    .calandar{
      width: 95% !important; 
      font-size: 10px !important;
      
      /* padding-left: 60px !important;  */
      /* padding-right: 60px !important;  */

      text-align: center !important;
      align-items: center !important;


    }
    
  }
  
  
  