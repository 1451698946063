@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.998) , rgba(10, 10, 10, 1));
}

h1 {
  font-family: "Orbitron", sans-serif !important;
}

